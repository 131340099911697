@import '../../McKessonColors.scss';

.facility-selection-modal{
    .ant-table-tbody > tr.ant-table-row-selected > td {
        background: #DEE9F0; //mdsLightestBlue lightened 5%
    }

    .ant-table-tbody > tr > td {
        padding: 4px 4px;
    }   
}

.facility-selector-button {
    margin-top: 15px;
    vertical-align: top;

    .ant-btn-primary[disabled]{
        color: $mdsBlueLighter;
        background-color: $mdsBlue;
        vertical-align: inherit;
    }
}

@media screen and (max-width: 992px) {
    .facility-selector-button {
        margin-top: 0px;
        vertical-align: top;

        .ant-btn-primary[disabled]{
            margin-top: inherit;
            color: $mdsBlueLighter;
            background-color: $mdsBlue;
            vertical-align: inherit;
        }
    }
}

.facility-modal .ant-modal-body{
    padding-top: 16px;
}

.facility-selector-search .ant-input-group-addon{
    padding: 0px;
}
