@import '../../../McKessonColors.scss';

.reports-menu-divider{
    color: $mdsGrayCCCCCC;
    background-color: $mdsGrayCCCCCC;
}

.report-content-options-menu{
    width: 140px;
    border: 1px solid $mdsGrayCCCCCC;
}