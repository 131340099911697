@import '../../McKessonColors.scss';

.groups-content-area {

    padding: 8px 64px 4px 64px;

    .ant-divider{
        border-top: 1px solid $mdsGrayCCCCCC;
        margin: 8px 0 0px 0;
    }

    .ant-table-pagination.ant-pagination {
        margin: 16px 16px;
    }

    .table-action-cell {
        display: flex;
        justify-content: center;
    }

    .ant-table-tbody td{
        padding: 10px;
    }

    .ant-table-thead th{
        background-color: #DEE9F0; //mdsLightestBlue lightened 5%
    }

    .ant-table-placeholder {
        visibility: hidden;
    }

    .ant-empty-normal {
        visibility: visible;
    }
}

.edit-groups-header {
    height: 100%;
}

.group-btn {

    background-color: #005A8C;
    color: #FFFFFF;

    &-edit {
        @extend .group-btn;
        float: left;
        height:20px;
        padding-top: 0;
        padding-bottom: 0;
        margin: 2.5px 2.5px 2.5px 2.5px;
        font-size: 11px;
        background-color: #FFFFFF;
        color: #187099;

        &:hover {
            background-color: #005A8C;
            color: #FFFFFF;
        }
    }
    
    &-delete {
        @extend .group-btn;
        float: left;
        height:20px;
        padding-top: 0;
        padding-bottom: 0;
        margin: 2.5px 2.5px 2.5px 2.5px;
        font-size: 11px;
    }

    &-add {
        @extend .group-btn;
        float: right;

        &:hover {
            background-color: #FFFFFF;
            color: #187099;
        }
    }
}

.edit-group-members-view-container {
    min-height: 441px;
    margin-top: 25px;

    .ant-table-selection-column {
        padding-right: 16px;
        padding-left: 16px;
    }

    .ant-table-placeholder {
        visibility: hidden;
    }

    .ant-empty-normal {
        visibility: visible;
    }
}

.edit-group-members-view-container table {
    direction: rtl;
}

.edit-group-members-view-container th {
    direction: ltr
}

.edit-group-members-view-container td {
    direction: ltr
}

.edit-group-form-item {
    color: black;
    font-weight: bold;
    float: right;
    margin-right: 20px;
    padding-top: 5px;
}

.edit-group-modal-btn {
    background-color: #005A8C;
    color: #FFFFFF;

    &-add {
        @extend .edit-group-modal-btn;
        margin-left: 10px;
        float: left;
        width: 75px; 
    }

    &-remove {
        @extend .edit-group-modal-btn;
        float: right;
        margin-top: 15px;
        margin-bottom: 0px;
        padding-right: 0px;
        padding-left: 0px;
        width: 75px;
    }

    &:hover {
        background-color: #FFFFFF;
        color: #187099;
    }
}

.remove-btn-container {
    .ant-popover-message-title {
        padding: 0;
    }
}

