@import '../../McKessonColors.scss';

.folder-content-area {
    .ant-divider{
        border-top: 1px solid $mdsGrayCCCCCC;
        margin: 8px 0 12px 0;
    }

    .invert-colors-on-dropdown .ant-dropdown-open {
        color: white;
        background-color: $mdsBlue;
    }

    .invert-button-colors {
        color: white;
        background-color: $mdsBlue;
    }
}

.folder-spinner {
    top: 40%;
    right: 50%;
    position: absolute;
}

.modal-folder-delete-warning{
    color: $mdsRed;
    margin-left: 50px;
}

.report-sort-selector{
    font-size: 12px;
    line-height: 24px;
    margin-right: 5px;
    width: 180px;
    text-align: left;

    .ant-select-selector {
        border: 0.5px solid $mdsGrayCCCCCC !important;
    }
}

.report-sort-selector-item {
    font-size: 12px;
    min-height: 16px;
    line-height: 16px;
}

.report-pagination{
    .ant-pagination-item-active{
        border-color: $mdsBlueLighter;
        background: white;
    }
}

.breadcrumb-wrapper{
    overflow:hidden;
    text-overflow: ellipsis;
    .breadcrumb-disabled h4{
        color: $mdsGray999999;
        cursor: default;
    }
}

.breadcrumb-wrapper:hover {
    overflow:visible;
    height: fit-content;
}