@import "../../McKessonColors.scss";

.alerts-area {
  overflow: auto;

  .ant-table-thead th {
    background-color: #dee9f0; //mdsLightestBlue lightened 5%
  }

  .ant-table-tbody td {
    padding: 10px;
  }

  .ant-pagination-item-active {
    border-color: $mdsBlueLighter;
    background: white;
  }
}

.alerts-layout {
    padding: 8px 64px 4px 64px;

    &-loading {
        height: 85hv;
        width: 100%;
    }
}