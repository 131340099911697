@import '../../McKessonColors.scss';

.users-content-area {

    padding: 8px 64px 4px 64px;

    .ant-divider{
        border-top: 1px solid $mdsGrayCCCCCC;
        margin: 8px 0 0px 0;
    }

    .ant-table-pagination.ant-pagination {
        margin: 16px 16px;
    }

    .table-action-cell {
        display: flex;
        justify-content: center;
    }

    .ant-table-tbody td{
        padding: 10px;
    }

    .ant-table-thead th{
        background-color: #DEE9F0; //mdsLightestBlue lightened 5%
    }

    .ant-table-placeholder {
        visibility: hidden;
    }

    .ant-empty-normal {
        visibility: visible;
    }
}

.users-view-schedules-btn:hover {
    background-color: #005A8C;
    color: #FFFFFF;
}

.flex-container {
    display: flex;
    flex-direction: column;
    min-height: 1vh;
}

.edit-users-header {
    height: 100%;
}