.about {
    margin-left: 20px;

    &-img {
        padding: 0px 0px 0px 10px
    }

    &-h3{
        @extend .about;
        margin-bottom: 0px;
    }

    &-h6 {
        @extend .about;
        margin-top: 0px;
        color: black
    }

    &-divider {
        margin-top: 10px;
        margin-bottom: 10px;
    }

    &-para {
        @extend .about;
        margin-bottom: 10px;
        color: black;
    }

    &-rt-bullet {
        padding-right: 20px;
        padding-bottom: 15px;
    }

    &-clps-pnl {
        margin-left: 0px;
    }

    &-alert {
        margin-bottom: 20px;
    }
}