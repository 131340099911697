@import '../../McKessonColors.scss';

// Importing the McKessonColors.scss file

/* Push the last child of .ant-space to the far right */
.notifications-window-header {

    /* Set styling for the title element */
    .title {
        max-width: 310px;  // Set the maximum width of the title to 320 pixels
        color: black;
    }

    /* Push the last child of .ant-space to the far right */
    > .ant-space-item:last-child {
        margin-left: auto;
        margin-right: 5px;
    }

    /* For each direct child of .ant-space, except the last one, apply right margin */
    > *:not(:last-child) {
        margin-right: 10px;
    }
}

.popover-content {
    width: 528px;
}

.notifications-view-button {
    display: block;

    // Styling for a primary button when it is disabled
    &[disabled] {
        color: $mdsBlueLighter;
        background-color: $mdsBlue;
        vertical-align: inherit;
    }
}

.notifications-badge {

    display: block;
    // Styling for the badge dot
    .ant-badge-dot {
        width: 10px;
        min-width: 10px;
        height: 10px;
        background-color: $mdsOrange;
    }
}
