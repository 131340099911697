@import '../../McKessonColors.scss';


.explores-content-area {
    padding: 8px 64px 4px 64px;

    .ant-tabs-tab {
        background-color: rgba(0, 90, 140, 0.1) !important;
        color: rgba(0, 90, 140, 0.75);
        border: solid lightgray 1.5px !important;
    }

    .ant-tabs-tab-active {
        background-color: white !important;
        border-bottom: none !important;
    }
}

.explore-tile {
    height: 457px;

    .ant-card-head { 
        background-color: #005A8C;
        color: white;
    }

    .ant-card-actions > li > span {
        cursor: default;
    }

    &-list {
        column-count: 2;
        
        &-item {
            width: 100%;
            color: black;
        }
    }

    &-paragraph {
        height: 90px;
        padding-left: 10px;
        padding-right: 10px;
        color: black;
        overflow-y: auto;
    }

    &-example-types {
        height: 50px;
        padding-left: 10px;
        padding-right: 10px;
        color: black;
        overflow-y: auto;
    }
    
    &-paragraph::-webkit-scrollbar, &-example-types::-webkit-scrollbar {
        width:10px;
    }
    
    &-paragraph::-webkit-scrollbar-track, &-example-types::-webkit-scrollbar-track {
        background-color: rgba(0, 90, 140, 0.175);
        border-radius: 10px;
    }
    
    &-paragraph::-webkit-scrollbar-thumb, &-example-types::-webkit-scrollbar-thumb {
        background-color: rgba(0, 90, 140, 1);
        border-radius: 10px;
    }

    &-content {
        height: 325px;
    
        &-marketplace {
            display: flex;
            justify-content: center;
            align-items: center;
            height: 430px;
    
            .explore-add-more {
                @extend .explore-tile-content-marketplace;
                height: 60px;
                .ant-card-meta-title {
                    color: $mdsBlue;
                }
                .ant-card-meta-description {
                    color: black;
                }
            }
    
            .explore-add-more:hover {
                @extend .explore-tile-content-marketplace;
                height: 75px;
                cursor: pointer;
            }
        }
    }

    &-explore-btn {
        width: 150px;
    }
}

.marketplace-icon {
    font-size: 50px;
    color: #005A8C;
}

.explore-tile:hover {
    cursor: default;
}