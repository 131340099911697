@import './McKessonColors.scss';
@import './spinner.scss';
//https://designsystem.mckesson.com/?path=/docs/branding-colors--colors

$headerHeight: 64px;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size:14px
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

html {
 font-size:12px;
}

/* Hidden Scrollbar for main content */
.main-content{
  overflow-y:auto;
}

// locks webapp in landscape view
@media screen and (max-width: 767px) and (orientation: portrait) {
  html {
    transform: rotate(-90deg);
    transform-origin: left top;
    width: 100vh;
    height: 100vw;
    overflow-x: hidden;
    position: absolute;
    top: 100%;
    left: 0;
  }
}

//hides scrollbar
/*.main-content::-webkit-scrollbar{
  width: 0!important;
  display: none;
}*/

.ant-layout-header h3, .ant-layout-header h5{
  color: white;
}

.ant-breadcrumb {
  margin: 0 0 0 0; 
}

.anticon svg{
  vertical-align: 0;
}

.clickable {
  cursor: pointer;
}

.logo {
  height: 15px;
  width: 109px;
  margin-top:20px;
  margin-bottom:30px;
  margin-left: 20px;
}

.ant-breadcrumb h4{
  display: inline
}

.ant-alert-message {
  color: $mdsGray555555;
}

.ant-alert-close-icon .anticon-close{
  color: $mdsGray555555;
}

.ant-form-item-label > label {
  color: black ;
  font-weight: bold;
}
  
.ant-input{
  color: black;
}

.full-height-minus-header{
  height: calc( 100vh - 64px );
  //needs to change if header ever changes... unfortunately calc is css method that does not take sass variables
}

.breadcrumb-wrapper{
  height: 36px;
}

.full-height-minus-header-and-breadcrumbs{
  height: calc( 100vh - 100px );
  //header + breadcrumb height
}

.full-height-minus-header-and-explore-dropdown{
  height: calc( 100vh - 104px );
  //header + explore drowndown (32px) + padding (8px) height
}

.custom-link:hover {
  text-decoration: underline ;
  color: $mdsBlueDark;
}

.ant-modal-close-icon{
  color: white ;
}

.ant-checkbox-disabled.ant-checkbox-checked .ant-checkbox-inner::after {
    border-color: #005A8C;
}