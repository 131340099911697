.modal-list{
    margin-left: 120px;
    padding-bottom: 24px;
}

.modal-list .modal-list-item-disabled{
    padding: 4px 0;
}

.modal-list .modal-list-item-clickable {
    padding: 4px 0;
}

.modal-list-item-clickable {
    cursor: pointer;
    font-size: 18px;
}

.modal-list-item-disabled {
    cursor: default;
}

.modal-list-item-disabled .ant-list-item-meta-avatar{
    color:black;
    font-weight: normal;
}

.modal-list-item-disabled .ant-list-item-meta-title{
    color:black;
    font-weight: normal;
}

.modal-list-filter {
    //border-color: black;
    //color: black;
    width: 300px;
    margin-left: 120px;
    margin-bottom: 20px;
}

.modal-with-list-and-navigation .ant-alert {
   margin-bottom: 0px;
   margin-top: 16px;
}
