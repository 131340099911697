.multi-groups-list {
    max-height: 200px;
    overflow-y: auto;
}

.multi-groups-list::-webkit-scrollbar {
    width:10px;
}

.multi-groups-list::-webkit-scrollbar-track {
    background-color: rgba(0, 90, 140, 0.175);
    border-radius: 10px;
}

.multi-groups-list::-webkit-scrollbar-thumb {
    background-color: rgba(0, 90, 140, 1);
    border-radius: 10px;
}

.multi-groups-btn {
    float: right;
}

.multi-groups-btn:hover {
    background-color: #005A8C;
    color: white;
}