@import '../../../McKessonColors.scss';

.btn-list-action {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.btn-list-action-text {
    font-size: 1em;
    white-space: nowrap;
}

@media (max-width: 50em) {
    .btn-list-action-text {
        font-size: 0.5em;
    }
}

.tbl-drug-list {
    min-height: 500px;

    .antd-table-body {
        min-height: 1000px;
    }

}

.tbl-drug-list-wrapper {
    @media (max-width: 1024px) {
        width: 1000px;
    }
}