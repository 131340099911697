@import "../../McKessonColors.scss";
//https://designsystem.mckesson.com/?path=/docs/branding-colors--colors

.schedule-area {
  .ant-table-thead th {
    background-color: #dee9f0; //mdsLightestBlue lightened 5%
  }

  .ant-table-tbody td {
    padding: 10px;
  }

  .ant-pagination-item-active {
    border-color: $mdsBlueLighter;
    background: white;
  }

  .send-now-button {

    &-content {
      display: "flex";
      align-content: "center";
      align-items: "center";
      text-align: "center";
      width: 78px;
    }

    &:hover {
      background-color: $mdsBlue;
      color: #fff;
    }

    
  }

  .sending-container {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px; // Adjust this value to change the space between the icon and the text
  }

}