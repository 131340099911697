@import '../../McKessonColors.scss';


.docs-content-area {
    padding: 8px 64px 4px 64px;

    .ant-table-thead th{
        background-color: #DEE9F0; //mdsLightestBlue lightened 5%
    }
    
    .ant-table-filter-trigger {
        width: 60px;
        color: gray;
    }
    
    .ant-table-pagination.ant-pagination{
        margin: 30px 30px;
    }

    #nested-table {
        margin-left: 40px;
    }

    .ant-table-filter-trigger-container {
        width: 40px;
    }
}

.explore-info-paragraph {
    color: black;
    padding-left: 10px;
}

.data-model-list {
    margin: 0;
    width: 100%;
    height: 50px;
    display: flex; 
    justify-content: flex-start;
    flex-direction: column;
    align-content: flex-start;
    flex-wrap: wrap; 
}

.data-model-list-item {
    flex-basis: 50%;
    width: 120%;
    color: black;
}

#nested-table {
    margin-left: 600px;

    .ant-table-thead th{
        background-color: #FBFBFB; 
    }
}

.view-document-btn:hover {
    background-color: #005A8C;
    color: #FFFFFF;
}

.data-model-div {
    .ant-table-row-expand-icon-cell {
        vertical-align: top;
    }
}
