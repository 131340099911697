
@import '../../McKessonColors.scss';
//https://designsystem.mckesson.com/?path=/docs/branding-colors--colors
  
  .monogram {
    height: 30px;
    width: 30px;
    margin-top:12px;
    margin-bottom:22px;
  }

  //Important is needed to overwrite some styles that are being applied
  // to ant design's menu items with higher specifity than CSS selectors 
  .custom-sidebar-menu .ant-menu-item-group-title { 
    font-weight: bold;
    font-size: 12px !important;
    padding: 4px 0px 4px 40px !important;
    margin-top: 0px  !important;
    margin-bottom: 0px  !important;
  }

  .custom-sidebar-menu .ant-menu-sub .ant-menu-item {
    font-size: 12px !important;
    padding: 0px 0px 0px 40px !important;
    margin-top: 0px  !important;
    margin-bottom: 0px  !important;
  }

  .custom-sidebar-menu .standard-reports-submenu .ant-menu-item {
    font-size: 12px !important;
    padding: 0px 0px 0px 10px !important;
    margin-top: 0px  !important;
    margin-bottom: 0px  !important;
}

  .custom-sidebar-menu .ant-menu-submenu-title {
    padding-left: 16px !important;
  }

  .custom-sidebar-menu .ant-menu-item {
    padding-left: 16px !important;
  }

  .custom-sidebar-menu .ant-menu-inline-collapsed > .ant-menu-item { 
    padding-left: 32px !important;
  }

  .custom-sidebar-menu .ant-menu-inline-collapsed > .ant-menu-submenu > .ant-menu-submenu-title  { 
    padding-left: 32px !important;
  }

  .custom-sidebar-menu .ant-menu-item-disabled {
    cursor: text;
  }

  .sidebar-icon {
    font-size: 24px !important;
  }

  .ant-menu-submenu-placement-rightTop .custom-sidebar-submenu-item{
    height: 25px !important;
    line-height: 25px !important;
  }

  .ant-menu-submenu-placement-rightTop .custom-sidebar-submenu-overview{
    height: 25px !important;
    line-height: 25px !important;
  }

  

  .ant-menu-submenu-placement-rightTop .custom-sidebar-submenu-group .ant-menu-item-group-title {
    height: 25px !important;
    line-height: 25px !important;
  }

  .ant-menu-submenu-placement-rightTop .ant-menu-vertical {
    padding-bottom: 10px;
  }

  .ant-menu-submenu-placement-rightTop  .custom-sidebar-submenu-group .ant-menu-item-group-title { 
    font-weight: bold;
    font-size: 11px !important;
    padding: 4px 8px 4px 20px !important;
    margin-top: 0px  !important;
    margin-bottom: 0px  !important;
  }

  .ant-menu-submenu-placement-rightTop .ant-menu-sub .custom-sidebar-submenu-item {
    font-size: 12px !important;
    padding: 0px 8px 0px 16px !important;
    margin-top: 0px  !important;
    margin-bottom: 0px  !important;
  }

  .ant-menu-submenu-placement-rightTop .ant-menu-sub .custom-sidebar-submenu-overview {
    font-size: 12px !important;
    padding: 0px 8px 0px 15px !important;
    margin-top: 10px  !important;
    margin-bottom: 0px  !important;
  }

  .custom-sidebar-trigger-expanded {
    text-align: left;
    
    .anticon{ 
      padding-left: 16px;
      padding-right: 12px;
    }
  }

  .custom-sidebar-border{
    border-right-width: 1.5px;
    border-right-style: solid;
    border-right-color: $mdsGray555555;
  }

  .custom-sidebar-submenu-item.ant-menu-item-disabled{
    color: $mdsGray555555 !important;
    cursor: text;
  }

  .custom-sidebar-submenu-overview.ant-menu-item-disabled{
    color: $mdsGray555555 !important;
    cursor: text;
  }

  .custom-sidebar-submenu-group .ant-menu-submenu-title{
    height: 25px !important;
    line-height: 25px !important;
    font-size: 13px !important;
  }

  .custom-sidebar-submenu-group .ant-menu-submenu-placement-rightTop .ant-menu-vertical{
    padding-bottom: 4px;
  }

  .ant-menu-inline .custom-sidebar-submenu-group .ant-menu-submenu-title{
    font-weight: bold;
    font-size: 12px !important;
    padding: 4px 8px 24px 40px !important;
  }

  .ant-menu-inline .custom-sidebar-submenu .ant-menu-submenu-title{
    margin-bottom: 0px  !important;
  }

  .ant-menu-vertical .custom-sidebar-submenu .ant-menu-submenu-title{
    margin-bottom: 0px  !important;
  }

  .submenu-lookalike-arrow {
    font-weight: 900 !important;
    font-size: 8px !important;
    float: right;
    padding-right: 18px;
  }

  .ant-menu-item-group-list .custom-sidebar-submenu2-group{
    padding-left: 10px;

    .ant-menu-item-group-title{
      font-size: 11px !important;
    }
  }