@import '../../../McKessonColors.scss';

.notifications-menu {
    /* Doubling the class to increase specificity.
       This ensures that these styles aren't easily overridden by other styles of the same specificity. */
    &.notifications-menu { 
        max-height: 75vh;
        overflow-y: auto;

        scrollbar-width: thin;
        scrollbar-color: $mdsBlue #f1f1f1;

        /* Styles specific to WebKit browsers for scrollbars */
        &::-webkit-scrollbar-button {
            display: none; // Hides scrollbar buttons
        }
        &::-webkit-scrollbar-track {
            background: #f1f1f1; // Sets the background color of the scrollbar track
        }
        &::-webkit-scrollbar-thumb {
            background-color: $mdsBlue; // Color of the draggable thumb
            border-radius: 8px; // Roundness of the thumb
        }
        &::-webkit-scrollbar {
            width: 10px; // Width of the entire scrollbar
        }
    }

    .ant-menu-item:active {
        background-color: transparent;
    }

    /* Doubling the class for specificity again */
    .notification.notification { 
        padding: 0;
        height: auto;
        min-height: 150px;
        margin-right: 10px;

        &.ant-menu-item {
            padding: 0 0 16px 0;
        }

        /* Push the last child of .ant-space to the far right */
        .notification-header {

            /* Set styling for the title element */
            .title {
                max-width: 310px;  // Set the maximum width of the title to 320 pixels
                color: black;
            }

            /* Push the last child of .ant-space to the far right */
            > .ant-space-item:last-child {
                margin-left: auto;
                margin-right: 5px;
            }

            /* For each direct child of .ant-space, except the last one, apply right margin */
            > *:not(:last-child) {
                margin-right: 10px;
            }

            .critical-notification-icon {
                color: $mdsRed;
            }
        }

        /* Styling for the Space component from Ant Design */
        .ant-space {
            display: flex; // Line up children in a row
            width: 100%; // Ensure the Space component stretches the full width of its parent
        }

        &-card-modal {
            cursor: pointer;
            border: solid 1px $mdsBlueLightest;
        }

        &-card {
            cursor: default;
             border: solid 1px $mdsBlueLightest;
        }

        

        /* Styles specific to the Ant Design Card component */
        .ant-card {

            width: 475px;
            height: auto;
            min-height: 150px;

            .circle-button {
                border: 1px solid transparent;
            }
            
            &:active,
            &:focus,
            &:hover {
              background-color: rgba(211, 211, 211, .2); // Set the background color to initial or any desired color
              border-radius: rgba(211, 211, 211, .2); // Set the border radius to initial or any desired value
          
              .circle-button {
                
                background-color: transparent;
                
                &:not(:disabled) {
                    border: 1px solid lightgray;
                }

                &:not(:disabled):hover {
                    background-color: rgba(211, 211, 211, .4);
                }

                // Styling for the button when the mouse is pressed down
                &:not(:disabled).mouse-down {
                    background-color: $mdsBlueLightest; // Light blue for mouse down
                }
              }
            }

            .notification-summary-container {
                position: relative;
            
                &:hover {
                    p, // added this
                    .toggle-more-less {
                        opacity: 1;
                    }
            
                    p.overflowing, 
                    p.expanded {
                        outline: 1px solid $mdsBlue; 
                        border-radius: 3px;
                        cursor: pointer;
                        background-color: rgba(211, 211, 211, .4);
            
                        /* Add negative margin-left to shift the outline to the left */
                        margin-left: -4px; 
                        padding-left: 4px;
                    }
                }
                
                /* Text truncation styles for paragraphs within the card */
                p {
                    position: relative; // Added for relative positioning
                    min-height: 70px;
                    width: 100%;
                    display: -webkit-box; // Old WebKit layout mode for text truncation
                    -webkit-box-orient: vertical; 
                    -webkit-line-clamp: 3; // Limit to 3 lines of text
                    word-wrap: break-word; // Wrap long words to the next line
                    overflow: hidden; // Hide overflowed content
                    text-overflow: ellipsis; // Add ellipsis for overflowed text
                    white-space: normal; // Allow text wrapping within the element
                    transition: max-height 0.4s ease-out; // Add transition for max-height

                }

                p.overflowing, p.expanded {
                    // Set the outline for both focus and hover states
                    &:hover,
                    &:focus {
                        outline: 1px solid $mdsBlue; 
                        border-radius: 3px;
                        cursor: pointer;
                        background-color: rgba(211, 211, 211, .4);

                        /* Add negative margin-left to shift the outline to the left */
                        margin-left: -4px; 
                        padding-left: 4px;
                    }
                }

                p.overflowing {
                    transition-delay: 0.3s;
                }

                p.expanded {
                    height: auto;
                    max-height: 500px;
                    display: block;  // Reset from -webkit-box
                    -webkit-line-clamp: none;
                    overflow: visible;
                    animation: lineInserted 0.3s forwards; 
                }
                
                .toggle-more-less {
                    position: absolute; // Absolutely position the button
                    bottom: 0; // Align it to the bottom of the .notification-summary-container
                    left: 0; // Start from the left edge
                    width: 100%; // Take the entire width
                    background-color: rgba(255, 255, 255, 0.7); // Background to make the text more readable
                    padding: 2px 0; // Some vertical padding for aesthetics
                    border-radius: 5px; // Optional rounding
                    cursor: pointer; // Indicates it's clickable
                    transition: opacity 0.3s; // Smoothens the fade in/out
                    opacity: 0; // Start invisible
                    text-align: center; // Center the text/content within the button
                    line-height: normal; // Adjust according to your needs to vertically center the text
                    margin-left: -4px;
                }
                
            }

            @keyframes lineInserted {
                from {
                    max-height: 0; // Start with no height
                }
                to {
                    max-height: 500px; // Expand to desired height
                }
            }

            /* Underline anchor tags on hover */
            a:hover {
                text-decoration: underline;
            }

            /* Prevent any before content from appearing for anchor tags */
            a::before {
                content: none;
            }

            a:focus {
                outline: 1px solid $mdsBlue; 
                border-radius: 3px;
                cursor: pointer;
          
                /* Add negative margin-left to shift the outline to the left */
                margin-left: -4px; 
                padding-left: 4px;
            }
        }

        /* Styling for the date within notifications */
        .date {
            color: #666666; // Gray text color
            font-weight: normal; // Regular font weight
        }
    }
}

.notification-alert {
    margin: 0px 0px 15px 0px;
}

.space-mark-all-read {

    width: 100%;

    .btn-mark-all-read {
        font-size: small;
        padding-top: 0px;
        padding-right: 0px;

        cursor: default;
    }

    .btn-mark-all-read-enabled {
        > span:hover {
            text-decoration: underline;
            cursor: pointer;
        }
    }

    .btn-mark-all-read-disabled {

    }

    /* Push the last child of .ant-space to the far right */
    > .ant-space-item:last-child {
        margin-left: auto;
        margin-right: 0px;
        margin-bottom: 5px;
    }
}

.unread-notification {
    background-color:  "#DEE9F0" !important;
}

.notifications-search {
    width: 300px;
    margin-bottom: 10px;
}

.link-button {

    span:hover {
        text-decoration: underline;
        cursor: pointer;
    }
}