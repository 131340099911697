@import '../../McKessonColors.scss';

.circle-button {
    width: 50px;
    height: 50px;
    border-radius: 50%; // Makes the button rounded
    border: 1px solid lightgray; // Default border color when not hovered
    background-color: white; // Default background color set to white
    outline: none; // Removes the default browser outline
    position: relative; // Sets positioning context for inner circle
    cursor: pointer; // Changes the mouse cursor to a hand when hovered
    transition: border-color 0.3s ease, box-shadow 0.3s ease; // Excludes background-color from the transition
    box-sizing: border-box; // Ensures border thickness doesn't increase button size
    vertical-align: middle; // Vertically centers the button in relation to neighboring elements

    &:disabled {
        cursor: not-allowed; // Changes the mouse cursor to a "not allowed" icon when disabled
    }
    
    // Changes the whitespace inside the button to a lighter gray when hovered
    &:hover {
        background-color: #f2f2f2; // Light gray for hover

        &::before {
            background-color: #f2f2f2; // Matches the hover color for the inner circle
        }
    }

    // Styling for when the button is focused (e.g., navigated via keyboard)
    &:focus {
        background-color: transparent; // Ensures the button's background remains transparent when focused
        outline: none; // Removes the default browser outline when focused
        box-shadow: 0 0 0 1.5px $mdsAzure;  // Adds a blue pseudo-outline
        border-color: 1px $mdsAzure; // Changes border color to blue when focused

        &::before {
            background-color: transparent; // Changes the inner circle to light blue when focused
        }
    }

    // Styling for the button when the mouse is pressed down
    &.mouse-down {
        background-color: #add8e6; // Light blue for mouse down

        &::before {
            background-color: transparent; // Make the inner circle transparent to let the parent's light blue show through
        }
    }

    &::before {
        content: '\2713';  // Unicode for checkmark
        display: flex;
        align-items: center;
        justify-content: center;
        width: 45%; 
        height: 45%;
        background-color: transparent;  // No background for inactive state
        border-radius: 50%;  // Keeps the inner circle rounded
        position: absolute;
        top: 50%; 
        left: 50%; 
        transform: translate(-50%, -50%);  // Centers the inner circle within the button
        transition: color 0.3s ease, background-color 0.3s ease;  // Smooth transitions for color changes
        border: none;  // No border for inactive state
        box-sizing: border-box;
        font-size: 15px;  // Adjust this to control the size of the checkmark
        color: $mdsBlue;  // Checkmark color initially set to blue
    }

    // Styling for the inner circle when the button has the "active" class
   &.active::before {
        content: '';
        background-color: $mdsBlue; // Solid blue background for active state
        color: white;  // Set the checkmark color to white for contrast against the blue background
        border: none;  // No border even in active state
    }
}
