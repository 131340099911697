@import '../../../McKessonColors.scss';

.ant-col.ant-form-item-label {
    padding-left: 0 !important; 
}

.btn-list-details {

    float: right;

    &-remove {
        @extend .btn-list-details;
        margin-left: 5px;
    }

    &-import {
        @extend .btn-list-details;
        margin-left: 5px;
        margin-right: 5px;
    }

    &-add{
        @extend .btn-list-details;
        margin-left: 5px;
        margin-right: 5px;
    }

}

.tbl-row-list-details {
    font-weight: 0;

    .ant-table-cell {
        color:black;
    }
}

.tbl-list-details {
    min-height: 410px;   

    .ant-table-body {
        min-height: 310px;
    }

    .ant-table-body::-webkit-scrollbar {
        height: 8px;
        width: 8px;
    }
    
    .ant-table-body::-webkit-scrollbar-track {
        background: transparent;
    }
    
    .ant-table-body::-webkit-scrollbar-thumb {
        background-color: rgba(51, 51, 51, .2);
        border: 3px solid transparent;
        border-radius: 4px;
        transition: background-color 10s;
    }

    .ant-table-body::-webkit-scrollbar-thumb:hover {
        background-color: rgba(0, 90 , 140, 1);
    }
}

.drug-list-table-alert-wrapper {
    .csv-alert {
        margin-top: 10px;
        
        .ant-alert-message {
            font-size: 14px;
            margin-bottom: 4px;
        }
    }

    .ant-alert-with-description.ant-alert-no-icon {
        padding-top: 8px;
        padding-bottom: 8px;

        .ant-alert-close-icon {
            padding-top: 5px;
        }
    }
}
