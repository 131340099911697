.tbl-data-search { 

    .ant-table-body::-webkit-scrollbar {
        height: 8px;
        width: 8px;
    }

    .ant-table-body::-webkit-scrollbar-track {
        background: transparent;
    }

    .ant-table-body::-webkit-scrollbar-thumb {
        background-color: rgba(51, 51, 51, .2);
        border: 3px solid transparent;
        border-radius: 4px;
        transition: background-color 10s;
    }

    .ant-table-body::-webkit-scrollbar-thumb:hover {
        background-color: rgba(0, 90 , 140, 1);
    }
}