@import '../../../McKessonColors.scss';

.folder-content-list-area{

    .ant-table-cell{
        padding: 4px;
    }

    .ant-table-row{
        cursor: pointer;
        height: 70px;
    }

    .element-title {
        font-size: 15px;
        font-weight: 500;
        margin-bottom: 0;
    }

    .element-description {
        font-size: 12px;
        margin-bottom: 0;
    }

    .element-thumbnail {
        width: 100%;
        max-height: 60px;
        padding: 1px;
    }

    .ant-table-thead > tr > th {
        font-weight:normal;
        font-size: 12px;
        border-bottom-color: $mdsGrayCCCCCC;
    }

    .ant-dropdown-open {
        background-color: $mdsGrayDDDDDD;
    }

    .ant-table-tbody > tr > td {
        border-bottom-color: $mdsGrayCCCCCC;
    }
}