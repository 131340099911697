//originally from https://tools.mckesson.com/artifactory/api/npm/mcklabs-npm/@mcklabs/mds-core/-/@mcklabs/mds-core-1.9.1.tgz
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,300;0,400;0,500;0,700;0,900;1,300;1,400;1,500;1,700;1,900&display=swap');

$mdsBlue: #005A8C !default;
$mdsBlueDark: #004870 !default;
$mdsBlueDarker: #003654 !default;
$mdsBlueDarkest: #002438 !default;
$mdsBlueLight: #669CBA !default;
$mdsBlueLighter: #99BDD1 !default;
$mdsBlueLightest: #CCDEE8 !default;

$mdsRed: #CA303A !default;

$mdsOrange: #EF8200 !default;
$mdsOrangeDark: #BF6800 !default;
$mdsOrangeDarker: #8F4E00 !default;
$mdsOrangeDarkest: #603400 !default;
$mdsOrangeLight: #F5B466 !default;
$mdsOrangeLighter: #F9CD99 !default;
$mdsOrangeLightest: #FCE6CC !default;

$mdsPurple: #702C6A !default;
$mdsPurpleDark: #5A2355 !default;
$mdsPurpleDarker: #431A40 !default;
$mdsPurpleDarkest: #2D122A !default;
$mdsPurpleLight: #A980A6 !default;
$mdsPurpleLighter: #C6ABC3 !default;
$mdsPurpleLightest: #E2D5E1 !default;

$mdsGreen: #5A8E22 !default;
$mdsGreenDark: #48721B !default;
$mdsGreenDarker: #365514 !default;
$mdsGreenDarkest: #24390E !default;
$mdsGreenLight: #9CBB7A !default;
$mdsGreenLighter: #BDD2A7 !default;
$mdsGreenLightest: #DEE8D3 !default;

$mdsAzure: #4891DC !default;
$mdsAzureDark: #3A74B0 !default;
$mdsAzureDarker: #2B5784 !default;
$mdsAzureDarkest: #1D3A58 !default;
$mdsAzureLight: #91BDEA !default;
$mdsAzureLighter: #B6D3F1 !default;
$mdsAzureLightest: #DAE9F8 !default;

$mdsGold: #FFB500 !default;
$mdsGoldDark: #CC9100 !default;
$mdsGoldDarker: #996D00 !default;
$mdsGoldDarkest: #664800 !default;
$mdsGoldLight: #FFE199 !default;
$mdsGoldLighter: #F9CD99 !default;
$mdsGoldLightest: #FFF0CC !default;

$mdsRust: #B95915 !default;
$mdsRustDark: #944711 !default;
$mdsRustDarker: #6F350D !default;
$mdsRustDarkest: #4A2408 !default;
$mdsRustLight: #D59B53 !default;
$mdsRustLighter: #E3BDA1 !default;
$mdsRustLightest: #F1DED0 !default;

$mdsGray: #88746A !default;
$mdsGrayDark: #6D5D55 !default;
$mdsGrayDarker: #524640 !default;
$mdsGrayDarkest: #362E2A !default;
$mdsGrayLight: #AEA6A3 !default;
$mdsGrayLighter: #CFC7C3 !default;
$mdsGrayLightest: #E7E3E1 !default;

$mdsGray333333: #333333 !default;
$mdsGray555555: #555555 !default;
$mdsGray999999: #999999 !default;
$mdsGrayBBBBBB: #BBBBBB !default;
$mdsGrayCCCCCC: #CCCCCC !default;
$mdsGrayDDDDDD: #DDDDDD !default;
$mdsGrayEEEEEE: #EEEEEE !default;
$mdsGrayF7F7F7: #F7F7F7 !default;


$robotoFont: 'Roboto', Arial, Verdana, Geneva, Tahoma, sans-serif;
$georgiaFont: Georgia, 'Times New Roman', Times, serif;
$min-font-size: 10px;
$max-font-size: 54px;
$fontWeight: 300;
$fontColor: $mdsGray333333;