@import '../../../McKessonColors.scss';

.folder-content-cards {
    .ant-card-bordered {
        border: 0.5px solid $mdsGrayBBBBBB;
    }

    .ant-card-body {
        border: 0.5px solid $mdsGrayBBBBBB;
    }

    .ant-card-meta-description{
        color: $mdsGray555555;
        font-size: 12px;
        white-space: nowrap;
    }

    .ant-card-meta-title{
        height: 20px;
        font-size: 15px;
        color: $mdsGray555555;
    }

    .ant-card-hoverable:hover{
        background-color: $mdsGrayDDDDDD;
        border: 0.5px solid $mdsGray999999;
    }

    .card-cover-container .ant-btn {
        position: absolute;
        top: 3%;
        left: 87%;
        z-index: 1;
    }

    .card-cover-container img {
        min-height: 190px;
        max-height: 190px;
        width: 100%;
        padding-top: 2px;
    }

    .card-cover-container div {
        min-height: 190px;
        max-height: 190px;
        width: 100%;
        padding-top: 95px;
    }

    .card-cover-container {
        position: relative;
    }

    .ant-card-hoverable:hover .ant-card-cover img {
        opacity: 0.4;
        filter: alpha(opacity=40); /* msie */
    }

    .ant-card-hoverable:hover .ant-btn {
        background-color: white;
    }

    .ant-dropdown-open {
        background-color: $mdsGrayDDDDDD;
    }
}