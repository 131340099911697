
@import '../../McKessonColors.scss';
//https://designsystem.mckesson.com/?path=/docs/branding-colors--colors
  
  .header-search{
    width: 250px;
    vertical-align: middle;
    .ant-input {
      background-color: $mdsGrayF7F7F7;
      
    }
  }

  .header-user {
    text-align: right;
    cursor: pointer;
    display: inline-block;
  }

  .header-settings {
    @extend .header-user;
    margin-left: 10px;
  }

  .behind-dropdown{
    z-index: 1040 !important;
  }

  .icon-vertical-alignment {
    vertical-align: unset;
    margin-top: 15px;
  }

  .header-height {
    height: 64px;
  }

  @media screen and (max-width: 992px) {
    .icon-vertical-alignment {
      vertical-align: top;
      margin-top: unset;
    }

    .header-height {
      height: 110px;
    }
  }

  .print-designer-button .ant-btn-primary[disabled]{
    color: $mdsBlueLighter;
    background-color: $mdsBlue;
  }

  .print-designer-button .ant-btn-primary[disabled]:hover{
    color: $mdsBlueLighter;
    background-color: $mdsBlue;
  }

  .copy-loading-div {
    height: 100vh;
    width: 100vw;
    background-color: black;
    position: absolute;
    z-index: 15;
    opacity: .2;
  }