.grp-homepage-centered {
    height: 100%;
    padding: 0;
    margin-top: 10px;
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    justify-content: center;

    &-group-name {
        @extend .grp-homepage-centered;
        float: left;
        font-size: 15px;
    }

    .perm-select {
        width: 100%;
    }
}

.group-homepage-modal-header {
    margin-bottom: 10px;
    font-size: 16px;
}