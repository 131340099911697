
@import '../../McKessonColors.scss';
//https://designsystem.mckesson.com/?path=/docs/branding-colors--colors

$login-primary-background-color: $mdsBlue;


.login-label .ant-form-item-label > label{
    color: black ;
    font-weight: bold;
}

.login-button-wide {
    width: 100%
}

.login-blue-background {
    background-color: $login-primary-background-color;
}

.login-white-background {
    background-color: white;
    width: 385px;
    padding: 30px 40px;
}

.login-header {
    padding-left: 0px !important;
}

.login-input{
    color: black;
}

.login-footer {
    color: white;
}

.login-alert {
    max-width: 228px;
}

.login-help{
    color: black;
    font-size: 18px;
    font-weight: 500;
    height: 313px;
    padding-top: 20px;
    text-align: center;
}

.login-help-link{
    margin: auto;
    width: 100%;
}

.modal-new-realm-warning{
    color: $mdsRed;
    margin-left: 15px;
}

.login-form-item {
    margin-bottom: 8px
}